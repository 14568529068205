@use '../colors.scss' as colors;

.tabSelector {
  background-color: colors.$woodBrown_dark;
  color: colors.$white;
  align: center;
  font-family: "MorrisRomanBlack";
  font-size: 1.5em;
  text-align: center;
  border-color: colors.$black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-style: solid;
  border-width: 3px;

  &.active {
    background-color: colors.$woodBrown_light;
    border-bottom-style: none;
  }
}

.tabSelectorContainer {
  background-color: colors.$woodBrown_light;
  border-color: colors.$black;
  color: colors.$white;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-style: solid;
  border-width: 3px;
  border-top-style: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 4px;
}