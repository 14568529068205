@use '../colors.scss' as colors;

.woodBrown-button {
  border: 2px;
  border-style: solid;
  border-radius: 5px;
  border-color: colors.$woodBrown;
  background-color: colors.$woodBrown;
  color: colors.$white;

  .max-Width-300 {
    max-width: 300px;
  }

  &:hover {
    background-color: colors.$woodBrown_light;
    color: colors.$white;
  }

  &:active {
    background-color: colors.$woodBrown_dark;
    color: colors.$white;
  }
}