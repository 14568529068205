@use '../colors.scss' as colors;

.logo-image-large {
  margin: auto;
  max-width: 350px;
  align-self: center;
}

.kingdom-map-image {
  border: 5px solid colors.$woodBrown;
  border-radius: 30%;
}

.combat-gif-image {
  border: 5px solid colors.$woodBrown;
  border-radius: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 90%;
}

.center-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail-image {
  border: 2px solid colors.$black;
  border-radius: 5px;
  width: 80px;
  margin: 5px 0px 5px 0px;

  &.is-mobile {
    width: 60px;
  }
}

.thumbnail-image-guide {
  border: 2px solid colors.$black;
  border-radius: 5px;
  width: 60%;
  min-width: 100px;
  max-width: 150px;
}

.image-guide-quick-start {
  border: 2px solid colors.$black;
  border-radius: 5px;
  width: 400px;
}