@use '../colors.scss' as colors;

.darkStone-button {
  border: 2px;
  border-style: solid;
  border-radius: 5px;
  border-color: colors.$darkStone;
  background-color: colors.$darkStone;
  color: colors.$white;
  margin: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: colors.$darkStone_light;
    color: colors.$white;
  }

  &:active {
    background-color: colors.$darkStone_dark;
    color: colors.$white;
  }

  &.disabled {
    background-color: colors.$darkStone_light;
    border-color: colors.$darkStone_light;
    color: colors.$white;
  }
}