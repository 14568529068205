@use '../colors.scss' as colors;

.vassalTree {
  border: 2px solid colors.$black;
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
  color: colors.$white;
  background-color: colors.$woodBrown;
}

.vassalTreeScroll {
  overflow-x: auto;
}