$white: #FFFFFF;
$black: #000000;

$stone: #abaeaf;
$stone_light: #c0c0c0;
$stone_dark: #a9a9a9;

$darkStone: #454545;
$darkStone_light: #565656;
$darkStone_dark: #373737;

$wheat: #f5deb3;
$wheat_light: #ffebca;
$wheat_extra_light: #fff4db;
$wheat_dark: #d7c197;
$wheat_extra_dark: #b89f6f;

$iceBlue: #6be0e0;
$iceBlue_light: #5cefef;
$iceBlue_dark: #6cdae7;

$woodBrown: #704F32;
$woodBrown_light: #7f5a3a;
$woodBrown_dark: #6c4930;

$appleGreen: #8db600;
$appleGreen_light: #9acd00;
$appleGreen_dark: #86a626;

$errorRed: #940008;
$errorRed_light: #a70000;
$errorRed_dark: #7f1616;

$iceCappedMountain_Key: #ffffff;
$mountain_Key: #bcbcbc;
$hills_Key: #62bc7a;
$steppe_Key: #a4bc7c;
$forest_Key: #7ee500;
$plains_Key: #a7e263;
$scrubland_Key: #caa700;
$desert_Key: #fbff19;
$bog_Key: #b03aff;