@use '../colors.scss' as colors;

.navigation-header {
  background-color: colors.$woodBrown;
  color: colors.$white;
  align: center;
  font-family: "MorrisRomanBlack";
  font-size: 2em;
  border-bottom-color: colors.$woodBrown_dark;
  border-bottom-style: solid;
  border-bottom-width: 0.5em;

  .nav-button {
    text-align: center;

    &:hover {
      background-color: colors.$woodBrown_light;
    }
  
    &:active {
      background-color: colors.$woodBrown_dark;
    }
  }
}