@use '../colors.scss' as colors;

.wheat-button {
  border: 2px;
  border-style: solid;
  border-radius: 5px;
  border-color: colors.$wheat;
  background-color: colors.$wheat;
  color: colors.$black;

  &:hover {
    background-color: colors.$wheat_light;
    color: colors.$black;
  }

  &:active {
    background-color: colors.$wheat_dark;
    color: colors.$black;
  }
}