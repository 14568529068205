@use '../colors.scss' as colors;

.menu-button-container {
  position: absolute;
  top: 0;

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }

  .menu-button {
    border: 5px colors.$woodBrown_dark solid;
    border-radius: 5px;
    background-color: colors.$woodBrown;
    color: colors.$white;
    margin: 0.5em 0.5em 0em 0.5em;
    width: 1.8em;
    height: 1.8em;

    &:hover {
      background-color: colors.$woodBrown_light;
      color: colors.$white;
    }

    &:active {
      background-color: colors.$woodBrown_dark;
      color: colors.$white;
    }
  }

  .menu-button-menu {
    border: 5px colors.$woodBrown_dark solid;
    border-radius: 5px;
    background-color: colors.$woodBrown;
    color: colors.$white;
    margin: 0.5em;
    width: 6em;
    position: float;

    .delineator {
      height: 8px;
      border-bottom: 5px colors.$woodBrown_dark solid;
      background-color: colors.$stone;
    }

    .entry {
      border-bottom: 5px colors.$woodBrown_dark solid;
      &:hover {
        background-color: colors.$woodBrown_light;
        color: colors.$white;
      }
  
      &:active {
        background-color: colors.$woodBrown_dark;
        color: colors.$white;
      }
    }
  }
}