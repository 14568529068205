@use '../colors.scss' as colors;

.combat-unit-selector {

  border-radius: 5px;
  margin: 2px;
  border: 2px;
  border-color: colors.$woodBrown_dark;
  border-style: solid;
  background-color: colors.$wheat_dark;


  &.selected {
    background-color: colors.$appleGreen_light;
  }

  &.none-available {
    background-color: colors.$stone_dark;
  }
}

.combat-grid-table {
  margin-left: auto;
  margin-right: auto;
}

.combat-grid-component {
  border: 2px solid colors.$black;
  background-color: colors.$stone;
  width: 64px;
  height: 44px; 
  min-width: 64px;
  min-height: 44px; 
  user-select: none;

  &.red-edge {
    background-color: colors.$errorRed_light;
    width: 10px;
    height: 10px; 
    min-width: 10px;
    min-height: 10px; 
    border-top: 2px solid colors.$errorRed_light;
    border-bottom: 2px solid colors.$errorRed_light;
    border-right: 0px solid colors.$errorRed_light;
    border-left: 0px solid colors.$errorRed_light;
  }

  &.blue-edge {
    background-color: colors.$bog_Key;
    width: 10px;
    height: 10px; 
    min-width: 10px;
    min-height: 10px; 
    border-top: 2px solid colors.$bog_Key;
    border-bottom: 2px solid colors.$bog_Key;
    border-right: 0px solid colors.$bog_Key;
    border-left: 0px solid colors.$bog_Key;
  }
}

.combat-grid-image {
  width: 60px;
  height: 40px; 
  user-select: none;
}
