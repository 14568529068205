@use '../colors.scss' as colors;

html body {
  background-color: colors.$wheat;
}

#root {
  position: relative;
  min-height: 100vh;
}

.footer-padding {
  padding-bottom: 1.5em;
}

.align-to-left {
  justify-content: left;
  display: flex;
}

.align-to-right {
  justify-content: right;
  display: flex;
}

.modal-content { background: colors.$wheat_light !important;}