@use '../colors.scss' as colors;

.outer-fancy-border {
  background-color: colors.$stone_dark;
  height: 8px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.inner-fancy-border {
  background-color: colors.$errorRed_light;
  margin:2px 0px 2px 0px;
  
  height:4px;
  border-radius: 3px;
}

.outer-fancy-border-sm {
  background-color: colors.$stone_dark;
  height: 4px;
  border-radius: 3px;
  margin-bottom: 5px;
  width: 20px;
}

.inner-fancy-border-sm {
  background-color: colors.$errorRed_light;
  margin:1px 0px 1px 0px;
  
  height:2px;
  border-radius: 3px;
}