@use '../colors.scss' as colors;

.resource-upkeep-color {
  border: 2px solid colors.$black;
  border-radius: 8px;
  width: 16px;
  height: 16px;

  &.green {
    background-color: colors.$appleGreen;
  }

  &.blue {
    background-color: colors.$bog_Key;
  }

  &.yellow {
    background-color: colors.$desert_Key;
  }

  &.red {
    background-color: colors.$errorRed;
  }

  &.inline-left {
    display: inline-block;
    float: none;
    margin-left: 10px;
  }
}

.resource-table {
  border-bottom: 2px colors.$black solid;
}

.resource-table-bottom-selector {
  background-color: colors.$darkStone_light;
  color: colors.$white;

  &.bottom-border {
    border-bottom: 2px colors.$black solid;
  }

  .sub-entry-margin-class {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.resource-table-vassalized {
  background-color: colors.$errorRed_light;
  text-align: center;
}
.resource-table-sub-entry {
  background-color: colors.$wheat_light;

  color: colors.$black;

  &.clickable {
    &:hover {
      background-color:colors.$wheat;
    }
  }

  &.errorRed-light-background {
    background-color: colors.$errorRed_light;
      color: colors.$white;
  }

  &.bottom-border {
    border-bottom: 2px colors.$wheat_extra_dark solid;
  }

  &.top-border {
    border-top: 2px colors.$wheat_extra_dark solid;
  }

  &.side-borders {
    border-left: 2px colors.$wheat_extra_dark solid;
    border-right: 2px colors.$wheat_extra_dark solid;
  }

  &.left-border {
    border-left: 2px colors.$wheat_extra_dark solid;
  }

  &.right-border {
    border-right: 2px colors.$wheat_extra_dark solid;
  }

  &.wheat-background {
    background-color: colors.$wheat;
  }

  &.dark-wheat-background {
    background-color: colors.$wheat_dark;
  }

  &.apple-green-background {
    background-color: colors.$appleGreen_dark;
  }

  &.error-red-background {
    background-color: colors.$errorRed_light;
  }

  .sub-entry-margin-class {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  &.dark {
    background-color: colors.$wheat_dark;
  }

  &.slate {
    background-color: colors.$stone_light;
  }
}

.improvement-resource-table {
  &.bottom-border {
    border-bottom: 2px colors.$wheat_extra_dark solid;
  }

  &.right-border {
    border-right: 2px colors.$wheat_extra_dark solid;
  }
}

.background-color-red-wheat-blend {
  background-color: colors.$errorRed_light;
  color: colors.$white;
}