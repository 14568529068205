@use '../colors.scss' as colors;

.primary-header {
  font-family: "MorrisRomanBlack";
  font-size: 4em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
}

.secondary-header {
  font-family: "MorrisRomanBlack";
  font-size: 3em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
}

.tertiary-header {
  font-family: "MorrisRomanBlack";
  font-size: 2em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
}

.quaternary-header {
  font-family: "MorrisRomanBlack";
  font-size: 1.5em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
}

.morris-sm {
  font-family: "MorrisRomanBlack";
  font-size: 1em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }

  &.bgRed {
    background-color: colors.$errorRed_light;
    border: 2px colors.$black solid;
    border-radius: 3px;
    margin: 2px;
  }
  &.bgGreen {
    background-color: colors.$appleGreen_light;
    color: colors.$black;
    border: 2px colors.$black solid;
    border-radius: 3px;
    margin: 2px;
  }
}

.morris-md {
  font-family: "MorrisRomanBlack";
  font-size: 1.2em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
  &.green {
    color: colors.$appleGreen
  }
  &.red {
    color: colors.$errorRed_light
  }
}

.morris-lg {
  font-family: "MorrisRomanBlack";
  font-size: 1.5em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
  &.green {
    color: colors.$appleGreen
  }
  &.red {
    color: colors.$errorRed_light
  }

  &.bgRed {
    background-color: colors.$errorRed_light;
    color: colors.$white;
    border: 2px colors.$black solid;
    border-radius: 3px;
    margin: 2px;
  }
}

.standard-text-sm {
  font-family: "Junicode";
  font-size: 0.80em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
}

.standard-text {
  font-family: "Junicode";
  font-size: 1em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
}

.standard-text-md {
  font-family: "Junicode";
  font-size: 1.2em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
}

.standard-text-lg {
  font-family: "Junicode";
  font-size: 1.5em;
  text-align: center;

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
}

.standard-text-box {
  border: 1px solid colors.$darkStone_light;
  border-radius: 3px;
  background-color: colors.$wheat_light;
  margin: 5px;

  &.clickable {
    &:hover {
      background-color:colors.$wheat_extra_light;
    }
  }
}

.clear-link-text-decoration {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:active {
    text-decoration: none;
    color: inherit;
  }
}

.green-text-box {
  border: 1px solid colors.$darkStone_light;
  border-radius: 3px;
  background-color: colors.$appleGreen_light;
  padding: 5px;
  margin: 5px;
  text-align: center;
  font-size: 1.5em;
}

.red-text-box {
  border: 1px solid colors.$darkStone_light;
  border-radius: 3px;
  background-color: colors.$errorRed_light;
  color: colors.$white;
  padding: 5px;
  margin: 5px;
  text-align: center;
  font-size: 1.5em;
}

.centered-list {
  text-align: center;

  &.no-dot {
    list-style-type: none;
  }
}