@use '../colors.scss' as colors;

.sub-entry-top-border {
  border-top: 2px colors.$wheat_extra_dark solid;
}

.guide-entry {
  background-color: colors.$wheat_light;
  color: colors.$black;

  &.clickable {
    &:hover {
      background-color:colors.$wheat_extra_light;
    }
  }

  &.errorRed-light-background {
    background-color: colors.$errorRed_light;
      color: colors.$white;
  }

  &.bottom-border {
    border-bottom: 2px colors.$wheat_extra_dark solid;
  }

  &.top-border {
    border-top: 2px colors.$wheat_extra_dark solid;
  }

  &.wheat-background {
    background-color: colors.$wheat;
  }

  &.dark-wheat-background {
    background-color: colors.$wheat_dark;
  }

  &.apple-green-background {
    background-color: colors.$appleGreen_dark;
  }

  &.error-red-background {
    background-color: colors.$errorRed_light;
  }

  .sub-entry-margin-class {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  &.dark {
    background-color: colors.$wheat_dark;
  }

  &.slate {
    background-color: colors.$stone_light;
  }
}

.improvement-resource-table {
  &.bottom-border {
    border-bottom: 2px colors.$wheat_extra_dark solid;
  }

  &.right-border {
    border-right: 2px colors.$wheat_extra_dark solid;
  }
}

.inline-block-display {
  display: inline-block;
  text-align: center;
}