@font-face {
  font-family: "Junicode";   /*Can be any text*/
  src: local("Junicode-Bold."),
    url("../fonts/Junicode-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "MorrisRomanBlack";   /*Can be any text*/
  src: local("MorrisRomanBlack"),
    url("../fonts/MorrisRomanBlack.ttf") format("truetype");
}