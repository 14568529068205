
@use '../colors.scss' as colors;
.footer {
  background-color: colors.$woodBrown;
  color: colors.$white;
  align: center;
  font-family: "MorrisRomanBlack";
  font-size: 1em;
  position: absolute;
  bottom: 0;
  height: 1.5em;

  .footer-text {
    text-align: center;
    min-width: 6em;
  }

  .footer-button {
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
    &:hover {
      background-color: colors.$woodBrown_light;
    }
  
    &:active {
      background-color: colors.$woodBrown_dark;
    }
  }
}