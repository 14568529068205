@use '../colors.scss' as colors;

.login-form {
  font-family: "Junicode";

  input[type=text] {
    background-color: inherit;
    color: inherit;
    border-bottom: 2px solid colors.$darkStone;
    border-radius: 0px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    margin: 5px;
    padding: 5px;

    font-size: 1em;
  }

  input[type=password] {
    background-color: inherit;
    color: inherit;
    border-bottom: 2px solid colors.$darkStone;
    border-radius: 0px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    margin: 5px;
    padding: 5px;
    
    font-size: 1em;
  }
}

.search-lands-form {
  font-family: "Junicode";

  input[type=text] {
    background-color: inherit;
    color: inherit;
    border-bottom: 2px solid colors.$darkStone;
    border-radius: 0px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    margin: 2px;
    padding: 2px;

    font-size: 1em;
  }

  input[type=password] {
    background-color: inherit;
    color: inherit;
    border-bottom: 2px solid colors.$darkStone;
    border-radius: 0px;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    margin: 2px;
    padding: 2px;
    
    font-size: 1em;
  }
}

.drop-down-style {
  border-bottom: 2px solid colors.$darkStone !important; 
  border-radius: 5px !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  margin: 5px !important;
  padding: 5px !important;

  &.wheat_light {
    background-color: colors.$wheat_light;
    color: colors.$black;
  }

  &.wheat {
    background-color: colors.$wheat;
    color: colors.$black;
  }

  &.wheat_dark {
    background-color: colors.$wheat_dark;
    color: colors.$black;
  }

  &.center-text {
    text-align: center;
  }
}