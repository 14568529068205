@use '../colors.scss' as colors;

.iceBlue-button {
  border: 2px;
  border-style: solid;
  border-radius: 5px;
  border-color: colors.$iceBlue;
  background-color: colors.$iceBlue;
  color: colors.$black;
  margin: auto;

  &:hover {
    background-color: colors.$iceBlue_light;
    color: colors.$black;
  }

  &:active {
    background-color: colors.$iceBlue_dark;
    color: colors.$black;
  }
}