@use '../colors.scss' as colors;

.mapKeyEntry {
  color: colors.$black;
  border: 2px solid colors.$black;
  border-radius: 5px;

  &.iceCappedMountain {
    background-color: colors.$iceCappedMountain_Key;
  }

  &.mountain {
    background-color: colors.$mountain_Key;
  }

  &.hills {
    background-color: colors.$hills_Key;
  }

  &.steppe {
    background-color: colors.$steppe_Key;
  }

  &.forest {
    background-color: colors.$forest_Key;
  }

  &.plains {
    background-color: colors.$plains_Key;
  }

  &.scrubland {
    background-color: colors.$scrubland_Key;
  }

  &.desert {
    background-color: colors.$desert_Key;
  }

  &.bog {
    background-color: colors.$bog_Key;
  }
}