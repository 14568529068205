@use '../colors.scss' as colors;

.pager {
  border: 1px solid colors.$darkStone;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  text-align: center;

  &.light-background {
    background-color: colors.$wheat_dark;
    color: colors.$black;
  }

  &.dark-background {
    background-color: colors.$woodBrown_light;
    color: colors.$white;
  }

  .pager-button {
    border: 1px solid colors.$darkStone;
    border-radius: 5px;
    background-color: colors.$darkStone;
    color: colors.$white;

    &:hover {
      background-color: colors.$darkStone_light;
      color: colors.$white;
    }
  
    &:active {
      background-color: colors.$darkStone_dark;
      color: colors.$white;
    }
  }
}